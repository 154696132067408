<template>
    <div>
        <!-- <b-card no-body class="mb-1 p-1">
            <div class="row">
                <div class="col-md-8">
                    <div>
                        <ul class="list-unstyled">
                            <b-media tag="li" class="mb-2" v-for="product in cartItems" :key="product.sc_sp_id">

                                <template #aside class="w-100">
                                    <b-carousel id="carousel-fade" fade indicators background="#ababab">
                                        <b-carousel-slide v-for="image in product.images" :key="image.spi_id"
                                            style="width: 100px;height: 100px;">
                                            <img :src="FILESURL + product.images[0].spi_image" alt=""
                                                style="object-fit: cover;width: 100px;height: 100px;">
                                        </b-carousel-slide>
                                    </b-carousel>
                                </template>
<h3 class="mt-0 mb-1">{{ product.sp_name }}</h3>
<div>
    <small v-html="product.sp_description" />
</div>
<p>{{ product.sc_quantity }}</p>
<v-select v-model="filters.sc_quantity"></v-select>
</b-media>
</ul>
</div>
</div>
<div class="col-md-4">
    Proceed to Buy
</div>
</div>
</b-card> -->

        <b-card>
            <h1>Cart</h1>
            <b-row style="height: 60vh" class="border p-2">
                <b-col cols="4" class="border-right">
                    <div class="d-flex border rounded-lg" style="height: 70px; padding: 5px; gap: 10px;">
                        <img style="height: 100%; width: 60px; object-fit: cover;" class="rounded-lg"
                            src="https://images.pexels.com/photos/1510392/pexels-photo-1510392.jpeg?auto=compress&cs=tinysrgb&w=800" />
                        <div class="d-flex flex-column justify-content-center">
                            <h4>Title</h4>
                            <p>100</p>
                        </div>
                    </div>
                </b-col>
                <b-col cols="8">Hello</b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BButton,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BImg,
    BMedia,
    BCarousel,
    BCarouselSlide,
    BTooltip,
} from "bootstrap-vue";

import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import VueSelect from "vue-select";
import AgentServices from "@/apiServices/AgentServices";
export default {
    components: {
        BCard,
        BButton,
        BFormInput,
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BImg,
        BMedia,
        BCarousel,
        BCarouselSlide,
        BTooltip,
        VueSelect,
    },
    data() {
        return {
            cartItems: [],
            FILESURL,
            filters: {
                sc_quantity: null
            },
            product: null,
        }
    },
    directives: {
        BTooltip
    },
    methods: {
        async getCartItems() {
            const res = await CommonServices.getCartItems()
            if (res.data.status) {
                this.cartItems = res.data.data.data
            }
        },
        async getSingleProduct(id) {
            try {
                const res = await AgentServices.getSingleProduct(id);
                if (res.data.status) {
                    this.product = res.data.data;
                }
            } catch (error) {
                console.error("Error in GetSingleProduct", error);
            }
        },

    },
    beforeMount() {
        this.getCartItems()
        // this.getSingleProduct()
    },
}
</script>
<style></style>